import React, { useState, useEffect } from 'react'

const breakpoints = {
  mobile: 699,
}

export const useMediaQuery = (breakpoint = 'mobile') => {
  const windowObj = typeof window === 'undefined' ? false : window
  const [width, setWidth] = useState(windowObj.innerWidth || 0)

  useEffect(() => {
    const windObj = typeof window === 'undefined' ? false : window
    if (windObj) {
      const handleResize = () => {
        setWidth(windObj.innerWidth)
      }

      windObj.addEventListener('resize', handleResize)

      return () => {
        windObj.removeEventListener('resize', handleResize)
      }
    }
  }, [windowObj])

  return width > (breakpoints[breakpoint] || breakpoint) ? false : true
}
