import React from 'react'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import clsx from 'clsx'
import get from 'lodash/get'

import { Facebook, Twitter } from '../Icons'

const SocialBar = ({ url, title, className = '', children }) => {
  const testWindow = typeof window === 'undefined' ? false : window
  const origin = get(testWindow || false, 'location.origin', false)
  const fullUrl = origin ? `${origin}${url}` : url

  return (
    <div className={clsx('social-bar', className)}>
      <FacebookShareButton url={fullUrl} title={title} className="social-bar__item">
        <Facebook className="social-bar__item-icon" />
      </FacebookShareButton>

      {/*<TwitterShareButton url={fullUrl} title={title} className="social-bar__item">
        <Twitter className="social-bar__item-icon" />
      </TwitterShareButton>*/}
    </div>
  )
}

export default SocialBar
