import React from 'react'

import {
  FaArrowCircleRight,
  FaArrowCircleLeft,
  FaArrowRight,
  FaFacebookF,
  FaTwitter,
} from 'react-icons/fa'

export const ArrowRight = (props) => <FaArrowRight {...props} />
export const ArrowCircleRight = (props) => <FaArrowCircleRight {...props} />
export const ArrowCircleLeft = (props) => <FaArrowCircleLeft {...props} />

export const Facebook = (props) => <FaFacebookF {...props} />
export const Twitter = (props) => <FaTwitter {...props} />
