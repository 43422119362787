import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import clsx from 'clsx'
import Img from 'gatsby-image'

import { ArrowRight } from '../Icons'

import { useMediaQuery } from '../Screen'

const PostsGrid = ({ posts, type = 'default', limit = 0, className = '' }) => {
  const isMobile = useMediaQuery('mobile')

  return (
    <div className={clsx('posts-grid', `posts-grid--${type}`, className)}>
      {posts.slice(0, limit || posts.length).map((post, i) => {
        const isLong = i === 0 || i === 6
        const isShort = i === 1 || i === 5
        const thePost = get(post, 'node', post)
        const titleLimit = 50
        const rawTitle = get(thePost, 'title', '').trim()
        const title =
          rawTitle.length > titleLimit && !isLong && !isMobile
            ? `${rawTitle.substring(0, titleLimit)}...`
            : rawTitle
        const slug = get(thePost, 'slug', '')
        const mainPhoto = get(thePost, 'mainPhoto.fluid', false)
        const categoryTitle = get(thePost, 'category.title', 'Mistakes')

        return (
          <div
            key={thePost.id || i}
            className={clsx(
              'post-grid-item',
              `post-grid-item--color-${i % 4}`,
              isLong && 'post-grid-item--long',
              isShort && 'post-grid-item--short',
            )}
          >
            <Link className="post-grid-item__link" to={`/blog/${slug}`}>
              {mainPhoto && (
                <div className="post-grid-item__link-photo">
                  <Img fluid={mainPhoto} className="photo" />
                </div>
              )}

              <div className="post-grid-item__link-background" />
              {categoryTitle && (
                <p className="post-grid-item__link-category">{categoryTitle}</p>
              )}

              <h3 className="post-grid-item__link-title">{title}</h3>
              <p className="post-grid-item__link-arrow">
                <ArrowRight className="arrow-right" />
              </p>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default PostsGrid
