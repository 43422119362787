import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import RichTextToReact from 'rich-text-to-react'
import { BLOCKS } from '@contentful/rich-text-types'
// import moment from 'moment'
// import Img from 'gatsby-image'

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import PostsGrid from '../components/Posts/Grid'
import SocialBar from '../components/Social/Bar'
import PostBanner from '../components/Posts/Banner'

// import mainPhoto from '../images/post-default-mainPhoto.png'

// these where used to manually inject banner but now is done inside contentful block
class RawNode {
  constructor(inner) {
    this.inner = inner
  }
}

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node, key) => {
      if (get(node, 'data.target.sys.contentType.sys.id', false) === 'ctaBanner') {
        const firstLine = get(
          node,
          'data.target.fields.firstLine[en-US]',
          "See if you're eligible for 100% coverage!",
        )
        const secondLine = get(
          node,
          'data.target.fields.secondLine[en-US]',
          'Get a free quote now',
        )

        const path = get(node, 'data.target.fields.path[en-US]', 'default')

        const alternativePhoneNumber = get(
          node,
          'data.target.fields.alternativePhoneNumber[en-US]',
          null,
        )

        return (
          <PostBanner
            key={key}
            firstLine={firstLine}
            secondLine={secondLine}
            alternativePhoneNumber={alternativePhoneNumber}
            path={path}
          />
        )
      }
      if (node && node.data && node.data.node instanceof RawNode) {
        const inner = node.data.node.inner
        if (inner && typeof inner === 'object') {
          return React.cloneElement(inner, { key })
        } else {
          return inner
        }
      } else {
        return null
      }
    },
  },
}

// const bannerBlock = {
//   nodeType: BLOCKS.EMBEDDED_ENTRY,
//   data: {
//     node: new RawNode(<PostBanner />),
//   },
//   content: [],
// }

const PostTemplate = ({ data, location }) => {
  const post = get(data, 'contentfulPost')

  const alternativePhoneNumber = get(post, 'alternativePhoneNumber', null)

  // const mainPhotoFile = get(post, 'mainPhoto.fluid', false)
  const relatedPosts = get(post, 'relatedPosts', [])
  const fullBody = get(post, 'fullBody.json', false)
  const shortBody = get(post, 'description.description', null)
  const categoryTitle = get(post, 'category.title', false)
  // const categorySlug = get(post, 'category.slug', false)
  // const date = get(post, 'date', get(post, 'createdAt', get(post, 'updatedAt', false)))
  // const author = get(post, 'author', false)
  // const authorPhoto = get(author, 'photo.fluid', false)
  // const authorName = get(author, 'fullName', false)

  // these where used to manually inject banner but now is done inside contentful block
  // const fullBodyWithBanner = () => {
  //   if (fullBody && fullBody.content && fullBody.content.length > 2) {
  //     return {
  //       ...fullBody,
  //       content: [
  //         ...fullBody.content.slice(0, 2),
  //         bannerBlock,
  //         ...fullBody.content.slice(2),
  //         bannerBlock,
  //       ],
  //     }
  //   }
  //   if (fullBody && fullBody.content) {
  //     return {
  //       ...fullBody,
  //       content: [...fullBody.content, bannerBlock],
  //     }
  //   }
  //   return fullBody
  // }

  const customTTY = get(location, 'pathname', false).includes(
    '/blog/does-medicare-cover-coronavirus-testing',
  )

  return (
    <Layout
      phone={alternativePhoneNumber ? alternativePhoneNumber : '866-740-2901'}
      location={location}
      TTYBig={customTTY}
    >
      <SEO
        title="Medicare Supplement Plans - Medicare Companion"
        keywords={['medicare', 'insurance', 'plans', 'affordable', 'supplement']}
      />
      <Header
        phone={alternativePhoneNumber ? alternativePhoneNumber : '866-740-2901'}
        noIntro
        grayLogo
        type="post"
        TTYBig={customTTY}
      />

      <main id="main">
        <div className="post-single">
          <div className="post-single__top">
            {categoryTitle && <p className="post-single__category">{categoryTitle}</p>}
            <SocialBar url={`/blog/${post.slug}`} title={post.title} />
          </div>

          <div className="post-single__header">
            <h2 className="post-single__title">{post.title}</h2>
          </div>

          {/*mainPhotoFile && (
            <Img
              className="post-single__main-photo"
              fluid={mainPhotoFile}
              alt={post.title}
            />
          )*/}

          <div className="post-single__content">
            {/*<div className="post-single__content-sidebar">
              <div className="post-single__author">
                {authorPhoto && (
                  <Img
                    className="post-single__author-photo"
                    fluid={authorPhoto}
                    alt={authorName}
                  />
                )}

                <div className="post-single__author-info">
                  {authorName && (
                    <p className="post-single__author-name">By {authorName}</p>
                  )}
                  {date && (
                    <p className="post-single__author-date">
                      {moment(date).format('MMM Do, YYYY')}
                    </p>
                  )}
                </div>
              </div>
                  </div>*/}

            <div className="post-single__content-description">
              {fullBody ? (
                <RichTextToReact document={fullBody} options={options} />
              ) : (
                shortBody
              )}
            </div>
          </div>
        </div>

        {relatedPosts && relatedPosts.length && (
          <div className="related-posts">
            <h2 className="related-posts_title">Related Posts</h2>

            <PostsGrid
              className="related-posts__entries"
              posts={relatedPosts}
              type="normal"
              limit="3"
            />
          </div>
        )}
      </main>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      slug
      id
      title
      alternativePhoneNumber
      createdAt(formatString: "Y-MM-DD")
      updatedAt(formatString: "Y-MM-DD")
      date(formatString: "Y-MM-DD")
      mainPhoto {
        file {
          url
        }
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
      category {
        id
        slug
        title
      }
      description {
        description
      }
      fullBody {
        json
      }
      author {
        fullName
        photo {
          file {
            url
          }
          fluid(maxWidth: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
      relatedPosts {
        title
        id
        slug
        mainPhoto {
          file {
            url
          }
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
