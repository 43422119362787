import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import clsx from 'classnames'

import phoneImage from '../../images/phone-woman.png'
import {
  nextStep,
  submitZip,
  toggleIncludeAddress,
  changeType,
} from '../../reducers/captive'

import styles from './banner.module.scss'

const PostBanner = ({
  submitZip,
  nextStep,
  toggleIncludeAddress,
  changeType,
  firstLine,
  secondLine,
  alternativePhoneNumber,
  path,
}) => {
  const [zip, setZip] = useState('')
  const [disabled, setDisabled] = useState(true)

  const tel = alternativePhoneNumber ? alternativePhoneNumber : '866-740-2901'

  const handleSubmit = (e) => {
    e.preventDefault()
    submitZip(zip)
    if (path === 'short') {
      toggleIncludeAddress(true)
      changeType('medicareAltOne')
    }
    nextStep('zip')
    navigate('/apply/birth')
  }

  const handleChange = (event) => {
    const isDisabled =
      event.target.value !== '' && event.target.value.length === 5 ? '' : 'disabled'

    setZip(event.target.value)
    setDisabled(isDisabled)
  }
  return (
    <div className={styles.banner}>
      <div className={styles.image}>
        <img src={phoneImage} alt=" " />
      </div>
      <div className={styles.middle}>
        <h3>{firstLine}</h3>
        <h3 className="t-bold pb2">{secondLine}</h3>
        <div className={styles.cta}>
          <form action="#" onSubmit={handleSubmit} noValidate="novalidate">
            <input
              className={styles.input}
              id="zip"
              placeholder="Zip Code"
              maxLength="5"
              type="text"
              data-required="true"
              value={zip}
              onChange={handleChange}
            />
            <button type="submit" disabled={disabled} className={clsx('btn', styles.btn)}>
              Start my quote
            </button>
          </form>
        </div>
        <span className={styles.or}>
          <strong>or</strong>
        </span>
        <div className={styles.phoneCta}>
          <span className={styles.indicator} />
          {/*<span className="icon-phone" />*/}
          <a href={`tel:${tel}`} className={styles.number}>
            <span className="text">{tel}</span>
          </a>
        </div>
        <div>TTY Users: 711</div>
        <em>your wait time is less than 9 seconds</em>
      </div>
    </div>
  )
}

PostBanner.propTypes = {
  submitZip: func.isRequired,
  nextStep: func.isRequired,
  toggleIncludeAddress: func.isRequired,
  changeType: func.isRequired,
  firstLine: string,
  secondLine: string,
  path: string,
  alternativePhoneNumber: string,
}

PostBanner.defaultProps = {
  firstLine: "See if you're eligible for 100% coverage!",
  secondLine: 'Get a free quote now',
  path: 'default',
  alternativePhoneNumber: null,
}

export default connect((state) => state, {
  nextStep,
  submitZip,
  toggleIncludeAddress,
  changeType,
})(PostBanner)
